import React from "react"

import {graphql, useStaticQuery} from 'gatsby';
import Helpers from "../app/helpers"
import Layout from "../app/layouts/default"

import Button from "../app/atoms/button";
import * as Megacontent from "../app/helpers/megacontent";

import {Waypoint} from 'react-waypoint';


const {dynamic, gallery, hero, triplet, listingIcons, listingImages, infoModule, editorialPush} = Megacontent.data;

const MegacontentPage = (props) => {


    const query = useStaticQuery(
        graphql`
            {
                allBabelProdotto(
                    filter: {familiesIds: {}, primaryimage: {elemMatch: {imageType: {}}}, lineId: {eq: 777}}
                ) {
                    edges {
                        node {
                            attributes {
                                id
                                category {
                                  id
                                  nameCategory
                                  ordCategory
                                  parentCategory
                                }
                                fieldName
                                fieldType
                                fieldValue
                            }
                            lineId
                            key_prod
                            id
                            familiesIds {
                                title
                                id
                                triples {
                                    id
                                    image
                                    linkcall
                                    linkimg
                                    market_exception
                                    text
                                    titimg
                                    title
                                    video
                                }
                            }
                            brand_id
                            brandName
                            productName
                            primaryimage {
                                imageType
                                imageUrl
                            }
                            internal {
                                description
                                ignoreType
                                mediaType
                                content
                            }
                            triples {
                                id
                                image
                                linkcall
                                linkimg
                                market_exception
                                text
                                titimg
                                title
                                video
                            }
                            connectivity
                        }
                    }
                }
            }
        `
    );


    let products = query.allBabelProdotto.edges;
    products = Helpers.productsDataManipulation(products);

    const dealers = [

        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/419942/Sinergy%20(1)?version=1.0&t=1628782407372",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/267259/monclick.jpg?version=1.0&t=1625835814352",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/267248/Unieuro.jpg?version=1.0&t=1625835790394",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/267270/Mediaworld.jpg?version=1.0&t=1625835869781",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/267281/Euronics.jpg?version=1.0&t=1625835889750",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/419804/Expert?version=1.0&t=1628782248734",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/419844/Trony?version=1.0&t=1628782271749",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/276325/COMET.jpg?version=1.0&t=1626444810173",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/276336/Amazon.jpg?version=1.0&t=1626444849611",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/419881/MondoElettrodomestici?version=1.0&t=1628782338345",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/419918/StayOn%20(1)?version=1.0&t=1628782362487",
            link: "#",
            label: "Label",
        },
    
        {
            image: "https://d10o5wjp7xd2qd.cloudfront.net/adapt-image/276347/FreeShop%20IT.jpg?version=1.0&t=1626444926703",
            link: "#",
            label: "Label",
        },
    
    ];

    return (
        <Layout>
            {/* <h1 style={{textAlign: 'center'}}>Megacontent</h1> */}
            <section style={{margin: '40px 0'}}>
                <Megacontent.Dynamic introTitle={dynamic.introTitle} introText={dynamic.introText}
                                     introLabel={dynamic.introLabel} introLink={dynamic.introLink}
                                     items={dynamic.items.slice(0, 4)}/>
                <Megacontent.Dynamic items={dynamic.items.slice(0, 3)}/>
                <Megacontent.Dynamic items={dynamic.items.slice(0, 2)}/>
                <Megacontent.Dynamic items={dynamic.items.slice(0, 2)} layout="horizontal" align="left"/>
            </section>
            <section>
                <Megacontent.Gallery introTitle={gallery.introTitle} introText={gallery.introText} items={gallery.items}
                                     introLabel={gallery.introLabel} introLink={gallery.introLink}/>
                <Megacontent.Gallery withoutCaption={true} items={gallery.items2} breakpoints={{
                    320: {
                        spaceBetween: 10,
                        slidesPerView: 1
                    },
                    768: {
                        spaceBetween: 50,
                        slidesPerView: 'auto'
                    }
                }}/>
            </section>
            <h2 style={{textAlign: 'center'}}>Hero Module</h2>
            <section style={{margin: '40px 0'}}>
                <Megacontent.HeroModule
                    title={hero.title}
                    description={hero.description}
                    link={hero.link}
                    image={hero.image}
                    video={hero.video || false}
                    buttonLabel={hero.buttonLabel}
                />
            </section>

            <h2 style={{textAlign: 'center'}}>Compare</h2>
            <section style={{margin: '40px 0'}}>
                <Megacontent.Compare
                    products={products}
                    icon={'native-washing-front-load'}
                    title={"Lavatrici a carica frontale"}
                    showAttributesLabels={{
                        show: 'Mostra dettagli',
                        hide: 'Nascondi dettagli'
                    }}
                    productsLabel="Prodotti"
                />
            </section>
            <Megacontent.Triplet
                introTitle={"Triplet - Media"}
                introText={'Nullam id dolor id nibh ultricies vehicula ut id elit. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec ullamcorper nulla non metus auctor fringilla.'}
                introLabel={'Scopri di più'}
                introLink={'#'}
                items={triplet.items}/>

            <Megacontent.Triplet
                introTitle={"Triplet - Icons"}
                introText={'Nullam id dolor id nibh ultricies vehicula ut id elit. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec ullamcorper nulla non metus auctor fringilla.'}
                introLabel={'Scopri di più'}
                introLink={'#'}
                items={triplet.items2}/>
            <Megacontent.Editorial>
                <h2>
                    Editorial
                </h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Cras viverra tortor vitae semper dapibus. Praesent ex tellus, gravida ut vulputate in,
                    tempus sit amet nisl. Praesent sit amet semper ligula, vehicula faucibus
                    massa. <strong>Maecenas</strong> vulputate scelerisque nunc, a
                    ornare est ultrices et. Nunc suscipit eros quis condimentum rhoncus. Phasellus finibus mollis tortor
                    at accumsan.
                    Donec fermentum felis vel lacinia scelerisque. <mark>Mauris</mark> a scelerisque ex. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit.
                </p>

                <p>
                    Mauris gravida ut quam sed condimentum. <mark>Curabitur</mark> lobortis dolor id ultrices elementum.
                    Donec ornare tincidunt bibendum. Cras sapien nunc, accumsan quis ultricies ac, blandit a odio.
                </p>


                <ul>
                    <li><strong>Praesent</strong> vel tellus efficitur, ullamcorper justo ac, placerat ipsum.
                    </li>
                    <li>Integer aliquet, leo a lacinia vestibulum, tellus lectus <mark>vehicula</mark> ipsum, eget
                        posuere urna sapien ac nulla.
                    </li>
                    <li>Curabitur eu dolor varius, venenatis metus sed, ultricies nibh.
                    </li>
                    <li>In ullamcorper orci et nulla semper, id <strong>tincidunt</strong> nunc sollicitudin.
                    </li>
                </ul>

                <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>


                <p>
                    Mauris gravida ut quam sed condimentum. Curabitur lobortis dolor id ultrices elementum.
                    Donec ornare tincidunt bibendum. Cras sapien nunc, accumsan quis ultricies ac, blandit a odio.
                </p>
                <ol>
                    <li><strong>Praesent</strong> vel tellus efficitur, ullamcorper justo ac, placerat ipsum.
                    </li>
                    <li>Integer aliquet, leo a lacinia vestibulum, tellus <mark>lectus</mark> vehicula ipsum, eget
                        posuere urna sapien ac nulla.
                    </li>
                    <li>Curabitur eu dolor varius, venenatis metus sed, ultricies nibh.
                    </li>
                    <li>In ullamcorper orci et nulla semper, id <strong>tincidunt</strong> nunc sollicitudin.
                    </li>
                </ol>

                <Button className="primary"
                        color="blue" label="Scopri di più" link='#'/>
            </Megacontent.Editorial>
            <Megacontent.Listing
                introTitle="Listing - Icons"
                introText="Mauris gravida ut quam sed condimentum. Curabitur lobortis dolor id ultrices elementum.
                Donec ornare tincidunt bibendum. Cras sapien nunc, accumsan quis ultricies ac, blandit a odio."
                introLabel={'Scopri di più'}
                introLink={'#'}
                type={listingIcons.type}
                items={listingIcons.items}
            />
            <Megacontent.Listing
                type={listingIcons.type}
                items={listingIcons.items.slice(0, 3)}
            />
            <Megacontent.Listing
                type={listingIcons.type}
                items={listingIcons.items.slice(0, 2)}
            />
            <Megacontent.Listing
                type={listingIcons.type}
                items={listingIcons.items.slice(0, 1)}
            />
            <Megacontent.Listing
                introTitle="Listing - Images"
                introText="Mauris gravida ut quam sed condimentum. Curabitur lobortis dolor id ultrices elementum.
                Donec ornare tincidunt bibendum. Cras sapien nunc, accumsan quis ultricies ac, blandit a odio."
                introLabel={'Scopri di più'}
                introLink={'#'}
                type={listingImages.type}
                items={listingImages.items}
            />
            <Megacontent.Listing
                type={listingImages.type}
                items={listingImages.items.slice(0, 3)}
            />
            <Megacontent.Listing
                type={listingImages.type}
                items={listingImages.items.slice(0, 2)}
            />
            <Megacontent.Listing
                type={listingImages.type}
                items={listingImages.items.slice(0, 1)}
            />


            <section style={{margin: '40px 0'}}>
                <Megacontent.InfoModule
                    introTitle={infoModule.introTitle} introText={infoModule.introText}
                    introLabel={infoModule.introLabel} introLink={infoModule.introLink}
                    preTitle={infoModule.preTitle}
                    title={infoModule.title}
                    description={infoModule.description}
                    link={infoModule.link}
                    items={infoModule.info}
                />
            </section>


            <section style={{margin: '40px 0'}}>
                <Megacontent.Push
                    introTitle={editorialPush.introTitle} introText={editorialPush.introText}
                    introLabel={editorialPush.introLabel} introLink={editorialPush.introLink}
                    image={editorialPush.image} color={editorialPush.color} layout={editorialPush.layout}
                    pretitle={editorialPush.content.pretitle} title={editorialPush.content.title}
                    text={editorialPush.content.text}
                    callToAction={editorialPush.content.callToAction}/>
                <Megacontent.Push
                    image={editorialPush.image} color={editorialPush.color} layout={editorialPush.layout2}
                    pretitle={editorialPush.content.pretitle} title={editorialPush.content.title}
                    text={editorialPush.content.text}
                    callToAction={editorialPush.content.callToAction}/>
                <Megacontent.Push
                    image={editorialPush.image} color={editorialPush.color2} layout={editorialPush.layout}
                    pretitle={editorialPush.content.pretitle} title={editorialPush.content.title}
                    text={editorialPush.content.text}
                    callToAction={editorialPush.content.callToAction}/>
                <Megacontent.Push
                    image={editorialPush.image} color={editorialPush.color2} layout={editorialPush.layout2}
                    pretitle={editorialPush.content.pretitle} title={editorialPush.content.title}
                    text={editorialPush.content.text}
                    callToAction={editorialPush.content.callToAction}/>
            </section>

            <section style={{margin: '40px 0'}}>
                <Megacontent.ImagesList
                    introTitle="Dove Acquistare"
                    introText="Acquista da casa il tuo elettrodomestico Candy preferito scegliendo tra i migliori rivenditori online. Scorri verso il basso per trovare il negozio più vicino."
                    images={dealers}
                    itemsPerRow={5}
                />
            </section>
        </Layout>
    )
};

export default MegacontentPage;
