/**
 * ProductsDataManipulation
 * A Service should be used as helper functions
 * called globally from other components, modules and layouts.
 * Should expose static functions.
 * @class ProductsDataManipulation
 */

export default class Helpers {

	static productsDataManipulation(products, reverseAndConcatenate = true) {

		products.filter(p => p.node.productName === "CS4 1062DE/2-S").map(p => {
			p.node.productNameComposed = 'Lavatrice a carica frontale RapidÓ';
			p.node.productNameVerbose = 'Libera installazione, 10 Kg, 1400 RPM, bianca, classe A';
			p.node.chips = [
				{
					category: 'ecommerce',
					label: 'La più venduta'
				},
			]
			p.node.price = {
				old: '€300,00',
				current: '€275,00',
				storeLink: '/',
				storeCta: 'Acquista su Candy Shop',
			};
			p.node.wheretoBuy = true;
			return null //Array.prototype.map() expects a return value from arrow function 
		})
		products.filter(p => p.node.productName === "EXCSS 139TWME-11").map(p => {
			p.node.productNameComposed = 'Lavatrice a carica frontale RapidÓ';
			p.node.productNameVerbose = 'Libera installazione, 8 Kg, 1000 RPM, bianca, classe A';
			return null //Array.prototype.map() expects a return value from arrow function 
		})
		products.filter(p => p.node.productName === "RO14106DWMCRE-S").map(p => {
			p.node.productNameComposed = 'Lavatrice a carica frontale RapidÓ';
			p.node.productNameVerbose = 'Libera installazione, 10 Kg, 1400 RPM, antracite, classe A';
			p.node.chips = [
				{
					category: 'features',
					label: 'Smart'
				},
			];
			return null //Array.prototype.map() expects a return value from arrow function 
		})
		products.filter(p => p.node.productName === "RO441286DWMSE-S").map(p => {
			p.node.productNameComposed = 'Lavatrice a carica frontale RapidÓ';
			p.node.productNameVerbose = 'Libera installazione, 10 Kg, 1400 RPM, bianca, classe A';
			p.node.productDescription = 'Candy RapidÓ: il modo migliore per risparmiare tempo.';
			p.node.price = {
				old: '€300,00',
				current: '€275,00',
				storeLink: 'https://amazon.com',
				storeCta: 'Acquista su Candy Shop',
			};
			p.node.wheretoBuy = true;
			return null //Array.prototype.map() expects a return value from arrow function 
		})


		const chipsDemoCategories = [
			{
				name: "ecommerce",
				label: 'Ecommerce'
			},
			{
				name: "benefits",
				label: 'Benefit'
			},
			{
				name: "features",
				label: 'Smart'
			},
			{
				name: "warranty",
				label: 'Garanzia 10 anni'
			},
			{
				name: "offers",
				label: 'Offerta'
			},
			{
				name: "bestseller",
				label: 'Bestseller'
			},
		];


		products = reverseAndConcatenate === true ? products.concat(products.reverse()) : products;
		

		if (reverseAndConcatenate === true) {
			const chipsProducts = JSON.parse(JSON.stringify(products)).slice(0, 6);
			
			chipsDemoCategories.forEach((chip, idx) => {
				const temp = chipsProducts[idx];
				temp.node.chips = [
					{
						category: chip.name,
						label: chip.label
					}
				];
			});
	
			products = products.concat(chipsProducts.reverse());
		}


		return products;
	}

	static addEditorialPushToProductsList(products) {
		return products;
	}
}
